<template>
  <div justify="end">
    <v-dialog v-model="isModalOpen">
      <v-form ref="form" v-model="is_valid" lazy-validation>
        <v-card>
          <v-card-title class="pb-4">
            <span class="text-h3">{{ $t("transfer_csv") }}</span>
          </v-card-title>
          <v-card-text class="pa-0 grey lighten-4">
            <v-container>
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-file-input
                        dense
                        hide-details="auto"
                        outlined
                        @change="onFileChanged($event)"
                        type="file"
                        label="csv"
                        prepend-icon=""
                        append-icon="mdi-file"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                  <v-row v-if="upload_error">
                    <v-col cols="12">
                      <v-alert type="error" dense outlined class="pa-2 mb-0">
                        {{ upload_error }}
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <small>{{ $t("indicates_required_field") }}</small>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="closeForm()">
              {{ $t("close") }}
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="uploadCSV(transferData)"
              :disabled="!is_valid"
              :loading="is_uploading"
            >
              {{ $t("upload") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { validationRules } from "@/mixins/validationRules";
import transferProductService from "@/store/services/transfer-product-service";

export default {
  mixins: [validationRules],

  props: {
    isModalOpen: Boolean,
    toggleModal: Function,
  },

  computed: {
    filters: {
      get() {
        return this.$store.state.transferProducts.filters;
      },
      set(value) {
        this.$store.commit("transferProducts/SET_FILTERS", value);
      },
    },
  },

  data() {
    return {
      upload_error: "",
      transferData: {},
      is_valid: true,
      is_uploading: false,
      isVisibleDialog: false,
    };
  },
  methods: {
    closeForm() {
      this.$refs.form.reset();
      this.upload_error = "";
      this.is_uploading = false;
      this.transferData = {};
      this.toggleModal();
    },

    async uploadCSV(transferData) {
      if (this.$refs.form.validate()) {
        try {
          this.is_uploading = true;
          await transferProductService.uploadCSV(transferData);
          await this.$store.dispatch("transferProducts/list", {
            itemsPerPage: 30,
            ...this.filters,
          });

          this.$store.dispatch(
            "alerts/success",
            this.$t("created_successfully")
          );

          this.closeForm();
        } catch (error) {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
          this.is_uploading = false;
          this.upload_error = error.response?.data?.message;
        }
        this.is_uploading = false;
      }
    },

    onFileChanged(e) {
      this.transferData.csv = e;
    },
  },
};
</script>
